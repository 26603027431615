import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import xenomorph_abi from "../abi/xenomorph_abi.json";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Menu from "./menu";
import Notifications from "./notifications";
import Layout from "./Layout";
import moment from "moment";
import { GiZeusSword, Gi3DHammer } from "react-icons/gi";


function ActivityTracker() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [tokenIds, setTokenIds] = useState([]);
  const [assaulters, setAssaulters] = useState([]);
  const [debilitators, setDebilitators] = useState([]);
  const [name, setName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");  
  const [selectedEntry, setSelectedEntry] = useState(null);
  const spenderAddress = "0xbE26470C4ab73E11e6F0157Bc3eD70D72C79Be98";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const getAssaulters = async (tokenId) => {
    const contract721 = new ethers.Contract(
      spenderAddress,
      xenomorph_abi,
      provider
    );
    const result = await contract721.getAssaulters(tokenId);
    return result.toString();
  };

  const updateAssaulters = async () => {
    setLoading(true);
      let newAssaulters = [];
      for (const tokenId of tokenIds) {
        const result = await getAssaulters(tokenId);
        newAssaulters.push(result);
      }
      setAssaulters(newAssaulters);
      console.log(assaultData);
      setLoading(false);
  };

  const getDebilitators = async (tokenId) => {
    const contract721 = new ethers.Contract(
      spenderAddress,
      xenomorph_abi,
      provider
    );
    const result = await contract721.getDebilitators(tokenId);
    return result.toString();
  };

  const updateDebilitators = async () => {
    setLoading(true);
      let newDebilitators = [];
      for (const tokenId of tokenIds) {
        const result = await getDebilitators(tokenId);
        newDebilitators.push(result);
      }
      setDebilitators(newDebilitators);
      console.log(debilitateData);
      setLoading(false);
  };

  const handleSubmit = () => {
    const inputTokenIds = inputValue.split(",");
    const uniqueTokenIds = [...tokenIds];
    for (const tokenId of inputTokenIds) {
        if (!uniqueTokenIds.includes(tokenId)) {
            uniqueTokenIds.push(tokenId);
        }
      }
      setTokenIds(uniqueTokenIds);
  };

  const handleRemove = () => {
  const inputTokenIds = inputValue.split(",");
  setTokenIds(tokenIds.filter(id => !inputTokenIds.includes(id)));
};    

const getPlayerNames = async (attackerId, defenderId) => {
  const contract = new ethers.Contract(spenderAddress, xenomorph_abi, provider);
  const attacker = await contract.players(attackerId);
  const defender = await contract.players(defenderId);
  return {
    attackerName: attacker.name,
    defenderName: defender.name,
  };
};

const handleAssaulter = async (attackerId, defenderId, entry) => {
  const playerNames = await getPlayerNames(attackerId, defenderId);
  const namesArray = Object.values(playerNames);
  setName(namesArray);
  setSelectedEntry(entry);
  console.log(name);
};

const handleDebilitator = async (attackerId, defenderId, entry) => {
  const playerNames = await getPlayerNames(attackerId, defenderId);
  const namesArray = Object.values(playerNames);
  setName(namesArray);
  setSelectedEntry(entry);  
  console.log(name);
};

const assaultData = [];
assaulters.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const assault = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    assaultData.push(assault);
  }
});

const debilitateData = [];
debilitators.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const debilitate = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    debilitateData.push(debilitate);
  }
});

const mappedAssaulters = assaultData.reverse().map((assault, index) => {
  const attackerId = assault.Value[0];
  const defenderId = assault.Value[1];
  const stolenPoints = assault.Value[2];
  const formattedDate = moment(assault.Value[3]).format("MMMM Do YYYY");

  return (
    <div key={index} className="a-format" onClick={() => handleAssaulter(attackerId, defenderId, index)}>
      <GiZeusSword/> Xenomorph <span style={{color: "gold"}}>{attackerId}</span> attacked Xenomorph <span style={{color: "gold"}}>{defenderId}</span> for <span style={{color: "yellow"}}>{stolenPoints}</span> points on <span style={{color: "yellow"}}>{formattedDate}</span> <GiZeusSword/>
      {selectedEntry === index && name.length > 0 && (
        <div className="aBoard">
          <div className="fcs">
            <img src={`https://xenbox.xyz/xenomorph/${attackerId}.png`} className="notifyIcon brblack" /> 
            <img src={require("../assets/images/vs.gif")} className="vs" /> 
            <img src={`https://xenbox.xyz/xenomorph/${defenderId}.png`} className="notifyIcon brblack" />
          </div>
          <div style={{alignItems: "center"}} >
            {name[0]} <span className="gtext">assaults</span> {name[1]}
          </div>
        </div>
      )}
    </div>      
  );
});

const mappedDebilitators = debilitateData.reverse().map((debilitate, index) => {
  const attackerId = debilitate.Value[0];
  const defenderId = debilitate.Value[1];
  const stolenPoints = debilitate.Value[2];
  const formattedDate = moment(debilitate.Value[3]).format("MMMM Do YYYY");

  return (
    <div key={index} className="d-format" onClick={() => handleDebilitator(attackerId, defenderId, index)}>
      <Gi3DHammer /> Xenomorph <span style={{color: "rgb(31, 73, 238)"}}>{attackerId}</span> debilitated Xenomorph <span style={{color: "rgb(31, 73, 238)"}}>{defenderId}</span> for <span style={{color: "blue"}}> {stolenPoints} </span> points on <span style={{color: "blue"}}>{formattedDate}</span> <Gi3DHammer />
      {selectedEntry === index && name.length > 0 && (
      <div className="dBoard">
        <div className="fcs">
<img src={(`https://xenbox.xyz/xenomorph/${attackerId}.png`)} className="notifyIcon brblack" /> 
<img src={require("../assets/images/vs.gif")} className="vs" /> 
<img src={(`https://xenbox.xyz/xenomorph/${defenderId}.png`)} className="notifyIcon brblack" />
        </div>
        <div style={{alignItems: "center"}} >
        {name[0]} <span className="gtext">debilitates</span> {name[1]}
        </div>
        
      </div>
    )}
    </div>      
  );
});






  
  useEffect (() => {
    initConnection(); 
  }, []);

  useEffect(() => {
    const storedTokenIds = localStorage.getItem("tokenIds");
    if (storedTokenIds) {
      setTokenIds(JSON.parse(storedTokenIds));
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem("tokenIds", JSON.stringify(tokenIds));
  }, [tokenIds]);

return (
    <s.Screen image={cerberus}>      
        <Layout />
        <div className={"activitytracker"} align={"center"}>
            <div>
            <p className={"actheader"}>ACTIVITY TRACKER</p>
            {mappedAssaulters}
            {mappedDebilitators}
              <div>
              <button className={"abutton"} onClick={updateAssaulters} disabled={loading ? 1 : 0}>Track Assaulters</button>
              <button className={"dbutton"} onClick={updateDebilitators} disabled={loading ? 1 : 0}>Track Debilitators</button>
              </div> 
          </div>
        
        </div>

        <div className={"notifyInput"} align={"center"}>
        <div className={"texttheme twhite"} style={{fontWeight: "bold"}}>Track Xenomorphian Activity</div>
        <form > 
          <input className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                type="text" 
                placeholder="Enter ID(s) separated by commas"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                />
                <button className={"button0"} type="submit" onClick={handleSubmit}>Monitor</button>
         </form>

        <div className={"texttheme twhite"}style={{fontWeight: "bold"}}>Ignore Xenomorph Activity</div>
        <form >
            <input className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
            type="text"
            placeholder="Enter ID(s) separated by commas"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            />  
            <button className={"button0"} type="submit"  onClick={handleRemove}>Remove</button>
          </form>
      </div>  
     
      <Menu />
      <Notifications />      
    </s.Screen>
  );
}

export default ActivityTracker;
