import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import xenomorph_abi from "../abi/xenomorph_abi.json";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import { GiZeusSword, Gi3DHammer, GiWarPick, GiTrophyCup, GiLaurelsTrophy, GiStarMedal, GiWallet } from "react-icons/gi";

function Notifications() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [mintLogs, setMintLogs] = useState([]);
  const [levelLogs, setLevelLogs] = useState([]);  
  const [payoutsLog, setPayoutsLog] = useState([]); 
  const [assaultLogs, setAssaultLogs] = useState([]);   
  const [debilitateLogs, setDebilitateLogs] = useState([]); 
  const [assaultPayouts, setAssaultPayouts] = useState([]);
  const [debilitatePayouts, setDebilitatePayouts] = useState([]);
const [hideNotifications, setHideNotifications] = useState(true);
const spenderAddress = "0xbE26470C4ab73E11e6F0157Bc3eD70D72C79Be98";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum); 
      const signer = tempProvider.getSigner();
      setProvider(tempProvider);
      setAccount(accounts[0]);       
      setSigner(signer);      
    } else {
      console.log("Please install Metamask.");
    }
  };

  const handleHideNotifications = () => {
    setHideNotifications(!hideNotifications);
};

const buttonStyle = {
  backgroundColor: hideNotifications ? "gray" : "gold"
};

  useEffect(() => {
    initConnection();
  }, []);

  useEffect(() => {
  if (!provider || !signer) return;

  const contract = new ethers.Contract(spenderAddress, xenomorph_abi, signer);
  contract.on("TokenMinted", (tokenId, name, event) => {
    let mintdata = {
      tokenId: tokenId.toString(), name: name.toString(), event
    };
    setMintLogs((oldState) => [...oldState, mintdata]
    )
  });

  contract.on("AssaultEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
    let assaultdata = {
      attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
      stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(), event
    };
    setAssaultLogs((oldState) => [...oldState, assaultdata]
    )
  });

  contract.on("AssaultPayoutClaimed", (playerId, payreward, event) => {
    let assaultpayoutdata = {
      playerId: playerId.toString(), payreward: payreward.toString(), event
    };
    setAssaultPayouts((oldState) => [...oldState, assaultpayoutdata]
    )
  });

  contract.on("DebilitateEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
    let debilitatedata = {
      attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
      stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(), event
    };
    setDebilitateLogs((oldState) => [...oldState, debilitatedata]
    )
  });

  contract.on("DebilitatePayoutClaimed", (playerId, payreward, event) => {
    let debilitatepayoutdata = {
      playerId: playerId.toString(), payreward: payreward.toString(), event
    };
    setDebilitatePayouts((oldState) => [...oldState, debilitatepayoutdata]
    )
  });

  contract.on("LevelUpEvent", (playerId, level, event) => {
    let leveldata = {
      playerId: playerId.toString(), level: level.toString(), event
    };
    setLevelLogs((oldState) => [...oldState, leveldata]
    )
  });

  contract.on("PayoutsClaimed", (playerId, amount, event) => {
    let payoutsdata = {
      playerId: playerId.toString(), amount: amount.toString(), event
    };
    setPayoutsLog((oldState) => [...oldState, payoutsdata]
    )
  });
}, [provider, signer]);

return (
    <s.Screen image={cerberus}>
      <div className="notificationsbar" >
        <div><button className={"tblack nbmisc"} onClick={handleHideNotifications}
        style={buttonStyle}> {hideNotifications ? "Hide Notifications" : "View Notifications"}</button></div>
        <div className={"textstyle twhitesmoke"} style={{fontWeight: "bold"}}>Monitor Feeds: Yours, Allies or Enemies </div>
        <div className={"notifications"} style={{display: hideNotifications ? 'flex' : 'none'}}>
        {mintLogs.length === 0 ? null : (
              mintLogs.map((log, index) => (
                <div key={index} className={"notify"} style={{border: "2px solid violet"}}>               
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                  
                <p className={"notifyText"} style={{fontSize: "30px"}}><GiWarPick/></p>   
                <img className={"notifyImg brViolet"} src={(`https://xenbox.xyz/xenomorph/${log.tokenId}.png`)} alt="Minter" />
                  <p className={"notifyText"}>Xenomorph <span style={{color: "violet"}}>{log.tokenId}</span> Arrives!</p>               
                </div>
                </div>
              ))
            )
        }

        {assaultLogs.length === 0 ? null : (
            assaultLogs.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid yellow"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p className={"notifyText"}><GiZeusSword/></p>
                  <img className={"notifyImg brORed"} src={(`https://xenbox.xyz/xenomorph/${log.attackerId}.png`)} alt="Attacker" />
                  <p className={"notifyText"}> {log.attackerId} <span style={{color: "yellow"}}>VS</span> {log.defenderId}</p>
                  <img className={"notifyImg brORed"} src={(`https://xenbox.xyz/xenomorph/${log.defenderId}.png`)} alt="Defender" />
                  <p className={"notifyText"}> for <span style={{color: "yellow"}}>{log.stolenPoints} points</span>!</p>
                </div>                
              </div>
            ))
          )
        }

        {assaultPayouts.length === 0 ? null : (
            assaultPayouts.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid gold"}}>                
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><GiTrophyCup/></p>
                <img className={"notifyImg brGold"} src={(`https://xenbox.xyz/xenomorph/${log.playerId}.png`)} alt="AssaultPayout" />
                {log.playerId}
                <p className={"notifyText"}> just claimed <span style={{color: "gold"}}>{log.payreward} Xen</span>!</p>
              </div>
              </div>
            ))
          )
        }

        {debilitateLogs.length === 0 ? null : (
            debilitateLogs.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid teal"}}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><Gi3DHammer/></p>
                  <img className={"notifyImg brBlue"} src={(`https://xenbox.xyz/xenomorph/${log.attackerId}.png`)} alt="Attacker" />
                  <p className={"notifyText"}> {log.attackerId} <span style={{color: "teal"}}>VS</span> {log.defenderId}</p>
                  <img className={"notifyImg brBlue"} src={(`https://xenbox.xyz/xenomorph/${log.defenderId}.png`)} alt="Defender" />
                  <p className={"notifyText"}> for <span style={{color: "teal"}}>{log.stolenPoints} points</span>!</p>
              </div>
              </div>
            ))
          )
        }

        {debilitatePayouts.length === 0 ? null : (
            debilitatePayouts.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid teal"}}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><GiLaurelsTrophy/></p>
                <img className={"notifyImg brTeal"} src={(`https://xenbox.xyz/xenomorph/${log.playerId}.png`)} alt="DebilitatePayout" />
                {log.playerId}
                <p className={"notifyText"}> just claimed <span style={{color: "teal"}}>{log.payreward} Xen</span>!</p>
                </div>
              </div>
            ))
          )
        }

        {levelLogs.length === 0 ? null : (
            levelLogs.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid orange"}}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <p className={"notifyText"}><GiStarMedal/></p>
              <img className={"notifyImg brOrange"} src={(`https://xenbox.xyz/xenomorph/${log.playerId}.png`)} alt="LevelUp" />
                {log.playerId}
                <p className={"notifyText"}> levels up to <span style={{color: "orange"}}>{log.level}</span>!</p>
              </div>
              </div>
            ))
          )
        }

        {payoutsLog.length === 0 ? null : (
            payoutsLog.map((log, index) => (
              <div key={index} className={"notify"} style={{border: "2px solid magenta"}}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><GiWallet/></p>   
                <img className={"notifyImg brMagenta"} src={(`https://xenbox.xyz/xenomorph/${log.playerId}.png`)} alt="CashOut" />
                {log.playerId}
                <p className={"notifyText"}> just cashed out <span style={{color: "magenta"}}>{log.amount} Xen</span>!</p>
              </div>
              </div>
            ))
          )
        }
        </div>        
      </div>      
    </s.Screen>
  );
}

export default Notifications;
