import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import { Link } from "react-router-dom";

function Menu() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [approval, setApproval] = useState("");
  const contractAddress = "0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e";
  const spenderAddress = "0xbE26470C4ab73E11e6F0157Bc3eD70D72C79Be98";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const approve = async () => {
    try {
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(
        contractAddress, 
        abi, 
        signer);      
      const approved = ethers.utils.parseEther(approval);
      const tx = await contractERC.approve(spenderAddress, approved);
      await tx.wait();
      console.log("Approval succeeded");
    } catch (err) {
      console.error(err);
    }
  };

  const revoke = async () => {
    try {
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(
        contractAddress, 
        abi, 
        signer);
      const tx = await contractERC.approve(spenderAddress, 0);
      await tx.wait();
      console.log("Revoke succeeded");
    } catch (err) {
      console.error(err);
    }
  };

  function handleShowForm() {
    setShowForm(true);
  }

  function handleHideForm() {
    setShowForm(false);
  }

  function handleSubmit(e) {
    e.preventDefault();    
    approve();
    setApproval("");
    setShowForm(false);
  }

  useEffect (() => {
  initConnection();  
  }, []);

  return (
    <div>
      <div className="menu1">
      <Link to="/" ><div className="image-wrapper spinner" title="Return to Home"><img src={require("../assets/images/icons/home.png")} className="icons" /></div></Link> <br />
      <Link to="/regenerate" ><div className="image-wrapper spinner" title="Regenerate a Xenomorph"><img src={require("../assets/images/icons/regenerate.png")} className="icons" /></div></Link><br />
      <Link to="/assault" ><div className="image-wrapper spinner" title="Assault a Xenomorph"><img src={require("../assets/images/icons/assault.png")} className="icons" /></div></Link><br />
      <Link to="/claim-assault" ><div className="image-wrapper spinner" title="Claim 5 Assaults for a win"><img src={require("../assets/images/icons/claimAssault.png")} className="icons" /></div></Link><br />
      <Link to="/reset-timer" ><div className="image-wrapper spinner" title="Reset Your Function Limit"><img src={require("../assets/images/icons/resetTimer.png")} className="icons" /></div></Link><br />

       
      </div>

      <div className="menu2">
      <Link to="/mint" ><div className="image-wrapper spinner" title="Mint a Xenomorph"><img src={require("../assets/images/icons/mint.png")} className="icons" /></div></Link><br />    
      <Link to="/hatch-xenomorph" ><div className="image-wrapper spinner" title="Hatch Your Xenomorph"><img src={require("../assets/images/icons/hatch.png")} className="icons" /></div> </Link><br />
      <Link to="/debilitate" ><div className="image-wrapper spinner" title="Debilitate an Enemy"><img src={require("../assets/images/icons/debilitate.png")} className="icons" /></div></Link><br />

      <Link to="/update-name" ><div className="image-wrapper spinner" title="Assign another nickname"><img src={require("../assets/images/icons/rename.png")} className="icons" /></div></Link><br />
      <Link to="/activitytracker" ><div className="image-wrapper spinner" title="Track Xenomorphian Activity"><img src={require("../assets/images/icons/activitytracker.png")} className="icons" /></div></Link><br /> 


      </div>

      <div className={"menu3"}>
      <Link to="/arsenal" ><div className="image-wrapper spinner" title="Your Xenomorph Arsenal"><img src={require("../assets/images/icons/arsenal.png")} className="icons" /></div></Link><br />
      <Link to="/weaponize" ><div className="image-wrapper spinner" title="Weaponize Your Xenomorphs"><img src={require("../assets/images/icons/weaponize.png")} className="icons" /></div></Link><br />     
      <Link to="/payouts" ><div className="image-wrapper spinner" title="Withdraw Your Xen Winnings"><img src={require("../assets/images/icons/payout.png")} className="icons" /></div></Link><br />
      <Link to="/claim-debilitate" ><div className="image-wrapper spinner" title="Claim 5 Debilitations for a win"><img src={require("../assets/images/icons/claimDebilitate.png")} className="icons" /></div></Link><br />
      <Link to="/level-up" ><div className="image-wrapper spinner" title="Level up a Xenomorph"><img src={require("../assets/images/icons/levelup.png")} className="icons" /></div></Link><br />


      </div>
      
      <div className="menu4">
      <div >
      <div onClick={handleShowForm}>
      <div className="image-wrapper" title="Approve Access to Xen">
        <img src={require("../assets/images/icons/approval.png")} className="icons" /></div>
      </div>
              {showForm && (
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      className={"inputform"}
                      style={{ color: "white", border: "3px solid goldenrod" }}
                      type="text"
                      placeholder="...best over 20 mil Xen"
                      value={approval}
                      onChange={(e) => setApproval(e.target.value)}
                      alt="Approval Amount"
                    />
                  </div>
                  <button className={"button1"} type="submit">Submit</button>
                  <button className={"button0"} type="button" onClick={handleHideForm}>Cancel</button>
                </form>
              )}
            </div>
            <br />
      <div className="image-wrapper box3" title="Revoke Access to Xen tokens" onClick={revoke}>
      <img src={require("../assets/images/icons/revoke.png")} className="icons" /></div><br /> 
      </div>
    </div>
  );
}

export default Menu;
